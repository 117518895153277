.participants {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 20px;
  padding: 10px;

  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 800px) {
  .participants {
    grid-template-columns: repeat(var(--grid-col-size), 1fr);
    grid-template-rows: repeat(var(--grid-row-size), 1fr);
  }
}
