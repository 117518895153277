.main-screen {
  width: 100%;
  height: 90vh;
  background: #3c4043;
}

.wrapper {
  width: 100%;
}

.footer {
  height: 10vh;
}
