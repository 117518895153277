.meeting-footer {
  position: fixed;
  bottom: 0;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  /* background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(200, 200, 200, 0.5)),
    to(rgba(150, 150, 150, 0.9)),
    color-stop(0.5, #555)
  ); */
}

.meeting-icons {
  color: white;
  border-radius: 50%;
  background: #3c4043;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fa3232;
}

.active {
  background-color: black;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
